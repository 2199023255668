<template>
  <div class="d-flex flex-column container-fluid">
    <h4>{{ $t('views.client.import.steps.step-3.general.total-lines', { length: items.length })}}</h4>
    <h4>{{ $t('views.client.import.steps.step-3.general.info') }}</h4>
    
    <div class="d-flex justify-content-between">
      <div class="recap mr-2">
        <h3>{{ $t('views.client.import.steps.step-3.general.subscriptions.title') }}</h3>
        <table class="custom-table" v-if="subscriptions.length > 0">
          <tr>
            <th>{{ $t('views.client.import.steps.step-3.general.file') }}</th>
            <th></th>
            <th>{{ $t('views.client.import.steps.step-3.general.subscriptions.correspondant') }}</th>
          </tr>
          <tr
            v-for="(fileSub, i) in subscriptions"
            :key="i"
          >
            <td>{{ fileSub.key }}</td>
            <td>=></td>
            <td>{{ fileSub.name }}</td>
          </tr>
        </table>
        <p v-else>{{ $t('views.client.import.steps.step-3.general.subscriptions.empty') }}</p>
      </div>
      <div class="recap ml-2">
        <h3>{{ $t('views.client.import.steps.step-3.general.tokens.title') }}</h3>
        <table class="custom-table" v-if="tokens.length > 0">
          <tr>
            <th>{{ $t('views.client.import.steps.step-3.general.file') }}</th>
            <th></th>
            <th>{{ $t('views.client.import.steps.step-3.general.tokens.correspondant') }}</th>
          </tr>
          <tr
            v-for="(fileSub, i) in tokens"
            :key="i"
          >
            <td>{{ fileSub.key }}</td>
            <td>=></td>
            <td>{{ fileSub.name }}</td>
          </tr>
        </table>
        <p v-else>{{ $t('views.client.import.steps.step-3.general.tokens.empty') }}</p>
      </div>
    </div>

    <button class="submit" :disabled="importing" @click="$emit('on:click-import')">IMPORTER</button>
  </div>
</template>
<script>

export default {
  data: () => ({
  }),
  props: {
    subscriptions: {
      type: Array,
      default: []
    },
    tokens: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: []
    },
    importing: {
      type: Boolean,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/table/_custom-table.scss";
@import "@lazy/client/import/_client-step3.scss";
</style>
